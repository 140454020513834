import "./MetricList.css"

function MetricsList(props) {
      const metrics = props.metrics;

        return (
            <div className="MetricList">
                <p>
               <b> Net Profit: {metrics.net_profit} </b>
                </p>
                <p>
                <b>Win trades, %: {metrics.win_trade_percent}</b>
                </p>
                <p>
                   Win trades: {metrics.win_trade_count}
                </p>
                <p>
                   All trades: {metrics.trade_count}
                </p>
                <p>
                <b>Average profit, $: {metrics.average_profit}</b>
                </p>
                <p>
                   Average win, $: {metrics.average_win}
                </p>
                <p>
                  Average loss, $: {metrics.average_loss}
                </p>
                <p>
                   Max drawdown, $: {metrics.max_drawdown}
                </p>
                <p>
                   Max drawdown, %: {metrics.max_drawdown_percent}
                </p>
                <p>
                    Sharp: {metrics.sharp}
                </p>
            </div>
        );
}

export default MetricsList;

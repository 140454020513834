import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
import 'antd/dist/antd.min.css';


function InstrumentInput() {
    const dispatch = useDispatch();
    const instrument = useSelector((state) => state.parameters.instrument);
    const instruments = useSelector((state) => state.instruments);

    const { Option } = Select;

    var data = instruments;
    const listItems = data.map((data) =>
        <Option key={data.symbol}>{data.description}</Option>
    );


    const onChange = (value) => {
        dispatch({ type: "SELECT_INSTRUMENT", payload: value });
    };


    return (
        <div className="MarginRight">
            <Select
                defaultValue={instrument}
                value={instrument}
                style={{
                    width: 200, marginBottom: 15
                }}
                showSearch
                placeholder="Select a market"
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
                {listItems}
            </Select>
        </div>
    );
}

export default InstrumentInput;

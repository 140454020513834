import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import "./CommonStyles.css"
import {Checkbox} from "antd";

function WMAFilter() {

    const dispatch = useDispatch()
    const wma = useSelector((state) => state.filters.wma);


    function WMAChange(e) {
        console.log(e.target.checked);
        dispatch({type: "SELECT_WMA_FILTER", payload: e.target.checked});
    }

    return (
        <div className="MarginRight">
            <p className="filter-label">WMA filter</p>
            <Checkbox onChange={WMAChange} checked={wma}>Enable</Checkbox>
        </div>
    );
}

export default WMAFilter;

import React from 'react';
import {useDispatch} from 'react-redux'
import "./CommonStyles.css"
import {Select} from "antd";

const {Option} = Select;

function DaysOfWeekFilter() {

    const dispatch = useDispatch()
    const daysOfWeek = [2, 3, 4, 5, 6]


    function daysOfWeekChange(value) {
        dispatch({type: "SELECT_DAYS_OF_WEEK_FILTER", payload: value});
    }

    return (
        <div className="MarginRight">
            <p className="filter-label">Days of week filter</p>
            <Select
                mode="multiple"
                allowClear
                defaultValue={daysOfWeek}
                style={{
                    width: '100%',
                }}
                placeholder="Select a day"
                onChange={daysOfWeekChange}
            >
                <Option value={2} key={2}>{"Monday"}</Option>
                <Option value={3} key={3}>{"Tuesday"}</Option>
                <Option value={4} key={4}>{"Wednesday"}</Option>
                <Option value={5} key={5}>{"Thursday"}</Option>
                <Option value={6} key={6}>{"Friday"}</Option>

            </Select>
        </div>
    );
}

export default DaysOfWeekFilter;

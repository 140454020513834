import React from "react";
import './Register.css'
import { Button} from 'antd';
import { useState } from 'react';
import RegisterModal from "./RegisterModal";


function Register({ isLoggedIn } ) {
    const [showModal, setShowModal] = useState(false);

    function onRegisterButtonClick() {
        setShowModal(true);
    }

    return (
        <div>
            {(!isLoggedIn) && <Button onClick={onRegisterButtonClick} className="register-button" type="link"> REGISTER </Button>}
            <RegisterModal open={showModal} closeModal={() => setShowModal(false)} />
        </div>
    )
}


export default Register;

import BacktestTab from "./BacktestTab/BacktestTab";
import WeeklyChartTab from "./WeeklyTab/WeeklyTab";
import DailyTab from "./DailyTab/DailyTab";
import COTMapTab from "./COTMapTab/COTMapTab";
import './TabsPanel.css';
import { Tabs } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

function TabsPanel() {
  const tabIndex = useSelector((state) => state.tabIndex);
  const dispatch = useDispatch();

  const onChange = (key) => {
    dispatch({ type: "SET_TAB_INDEX", payload: key });
  };

  const items = [
    {
      key: '1',
      label: 'COT MAP',
      children: <COTMapTab />,
    },
    {
      key: '2',
      label: 'WEEKLY',
      children: <WeeklyChartTab className='tabs-panel' />,
    },
    {
      key: '3',
      label: 'DAILY',
      children: <DailyTab className='tabs-panel' />,
    },
    {
      key: '4',
      label: 'BACKTEST',
      children: <BacktestTab className='tabs-panel' />,
    },
  ]

  return (
    <Tabs defaultActiveKey="1" activeKey={tabIndex} items={items} className='tabs-panels' onChange={onChange} />
  )
}

export default TabsPanel;

import { configureStore } from "@reduxjs/toolkit";

const initialState = {
    api_url: 'https://greatetrade.com/api/',
    // api_url: 'http://158.160.10.115/api/',

    // api_url: 'http://127.0.0.1:8000/api/',

    watchlist_path: 'watchlist/',
    cot_index_path: 'indicators/cot_index/',
    login_path: 'login/',
    register_path: 'register/',

    parameters: {
        "instrument": "NYMEX:CL1!",
        "pattern": 2,
        "risk_reward": 2,
        "start_capital": 50000
    },
    filters: {
        "direction": "BS",
        "stop_size": 2000,
        "days_of_week": [2, 3, 4, 5, 6],
        "month": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        "dma": false,
        "wma": false,
        "cot_filter": false
    },
    instruments: [],
    cot_map: [],
    pattern_types: [],
    cotMapCurrentRow: 0,
    tabIndex: "1",
};


function consumer(state = initialState, action) {
    if (action.type === 'SET_INSTRUMENTS') {
        return { ...state, instruments: action.payload }
    }

    if (action.type === 'SET_PATTERNS') {
        return { ...state, pattern_types: action.payload }
    }

    if (action.type === 'SET_WEEKLY_QUOTES') {
        return { ...state, weeklyQuotes: action.payload }
    }

    if (action.type === 'SET_INDICATORS') {
        return { ...state, indicatorsWeeklyData: action.payload }
    }

    if (action.type === 'SELECT_INSTRUMENT') {
        return { ...state, parameters: { ...state.parameters, instrument: action.payload } }
    }

    if (action.type === 'SELECT_PATTERN') {
        return { ...state, parameters: { ...state.parameters, pattern: action.payload } }
    }

    if (action.type === 'SELECT_RISK_REWARD') {
        return { ...state, parameters: { ...state.parameters, risk_reward: action.payload } }
    }

    if (action.type === 'SELECT_STOP_SIZE') {
        return { ...state, filters: { ...state.filters, stop_size: action.payload } }
    }

    if (action.type === 'SELECT_DIRECTION') {
        return { ...state, filters: { ...state.filters, direction: action.payload } }
    }

    if (action.type === 'ADD_QUOTES') {
        return { ...state, quotes: action.payload }
    }

    if (action.type === 'SELECT_COT2_FILTER') {
        return { ...state, filters: { ...state.filters, cot_2_filter: action.payload } }
    }

    if (action.type === 'SELECT_DAYS_OF_WEEK_FILTER') {
        return { ...state, filters: { ...state.filters, days_of_week: action.payload } }
    }

    if (action.type === 'SELECT_MONTH_FILTER') {
        return { ...state, filters: { ...state.filters, month: action.payload } }
    }

    if (action.type === 'SELECT_DMA_FILTER') {
        return { ...state, filters: { ...state.filters, dma: action.payload } }
    }

    if (action.type === 'SELECT_WMA_FILTER') {
        return { ...state, filters: { ...state.filters, wma: action.payload } }
    }

    if (action.type === 'SELECT_COT_FILTER') {
        return { ...state, filters: { ...state.filters, cot_filter: action.payload } }
    }

    if (action.type === 'ADD_COT_MAP') {
        return { ...state, cot_map: action.payload }
    }

    if (action.type === 'SET_COT_MAP_CURRENT_ROW') {
        return { ...state, cotMapCurrentRow: action.payload }
    }

    if (action.type === 'SET_TAB_INDEX') {
        return { ...state, tabIndex: action.payload }
    }

    return state;
}

export const store = configureStore({
    reducer: consumer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})


// store.subscribe(() => {
//     console.log("store", store.getState());
// })
import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import "./CommonStyles.css"
import {Checkbox} from "antd";

function CotFilter() {

    const dispatch = useDispatch()
    const cot_filter = useSelector((state) => state.filters.cot_filter);


    function CotFilterChange(e) {
        dispatch({type: "SELECT_COT_FILTER", payload: e.target.checked});
    }

    return (
        <div className="MarginRight">
            <p className="filter-label">COT filter</p>
            <Checkbox onChange={CotFilterChange} checked={cot_filter}>Enable</Checkbox>
        </div>
    );
}

export default CotFilter;

import './App.css';
import MainWindow  from "./Components/MainWindow/MainWindow";
import Login from "./Components/MainWindow/Login/Login";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

function App() {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainWindow/>}/>
                <Route path="/login" element={<Login/>}/>
            </Routes>
        </Router>
    );
}

export default App;

import React from "react";
import './Login.css';
import LoginModal from './LoginModal';
import { useState } from 'react';
import { Button} from 'antd';


function Login({ isLoggedIn }) {
    const [showModal, setShowModal] = useState(false);

    function onLoginButtonClick() {
        setShowModal(true);
    }

    return (
        <div>
            {(!isLoggedIn) && <Button onClick={onLoginButtonClick} className="login-button" type="link"> LOGIN </Button>}
            <LoginModal open={showModal} closeModal={() => setShowModal(false)} />
        </div>
    )
}

export default Login;
import * as React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from "react";
import { Table, Input, Checkbox } from 'antd';
import "./COTMapTab.css";
import { Button } from "antd";
import scrollIntoView from 'scroll-into-view';
import SaveButton from './SaveButton/SaveButton';


function COTMap() {
    const cot_map = useSelector((state) => state.cot_map);
    const dispatch = useDispatch()
    const currentRow = useSelector((state) => state.cotMapCurrentRow);

    const onCommentChange = (key, index) => (e) => {
        const newData = JSON.parse(JSON.stringify(cot_map));
        newData[index][key] = e.target.value;
        dispatch({ type: "ADD_COT_MAP", payload: newData });
    };

    const onCheckboxChange = (key, index) => (e) => {
        const newData = JSON.parse(JSON.stringify(cot_map));
        newData[index][key] = e.target.checked;
        dispatch({ type: "ADD_COT_MAP", payload: newData });
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            width: '10%',
        },
        {
            title: 'Market',
            dataIndex: 'instrument_description',
            key: 'instrument_description',
            align: 'center',
            width: '20%',
            render: (value, record, index) => (
                <Button className="ref-button">{record.instrument_description}</Button>
            ),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        dispatch({ type: "SET_TAB_INDEX", payload: "2" });
                    },
                };
            },
        },
        {
            title: 'COT Index',
            dataIndex: 'cot_index',
            key: 'cot_index',
            align: 'center',
            width: '8%',
        },
        {
            title: 'COT Setup',
            dataIndex: 'cot_setup',
            key: 'cot_setup',
            align: 'center',
            width: '8%',
        },
        {
            title: 'COT Week',
            dataIndex: 'cot_week',
            key: 'cot_week',
            align: 'center',
            width: '8%',
        },
        {
            title: 'COT1',
            dataIndex: 'cot_1',
            key: 'cot_1',
            align: 'center',
            width: '7%',
            render: (value, record, index) => (
                <Checkbox checked={value} onChange={onCheckboxChange('cot_1', index)}></Checkbox>
            )
        },
        {
            title: 'COT2',
            dataIndex: 'cot_2',
            key: 'cot_2',
            align: 'center',
            width: '7%',
            render: (value, record, index) => (
                <Checkbox checked={value} onChange={onCheckboxChange('cot_2', index)}></Checkbox>
            )
        },
        {
            title: 'Multiplier',
            dataIndex: 'multiplier',
            key: 'multiplier',
            align: 'center',
            width: '8%',
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            align: 'center',
            render: (text, record, index) => (
                <Input value={text} onChange={onCommentChange('comment', index)} />
            )
        },
    ];

    const restoreScrollPosition = () => {
        scrollIntoView(document.querySelector('.scroll-row'), {
            align: {
                top: 0,
            },
        });
    }

    useEffect(
        () => {
            restoreScrollPosition();
        }, []
    )

    return (
        <div>
            <SaveButton />

            <Table
                tag="COTtable"
                columns={columns}
                dataSource={cot_map}
                rowKey="id"
                rowIndex="currentRow"
                pagination={false}
                size="small"
                scroll={{ x: 950, y: "calc(100vh - 130px)" }}

                rowClassName={(record, index) => {
                    let selector = '';
                    if (record.cot_setup === "S") {
                        selector = 'red-row';
                    }
                    if (record.cot_setup === "B") {
                        selector = 'green-row';
                    }
                    if (index === currentRow) {
                        selector = selector + ' scroll-row';    
                    }

                    return selector
                }
                }

                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            dispatch({ type: "SET_COT_MAP_CURRENT_ROW", payload: rowIndex });
                            dispatch({ type: "SELECT_INSTRUMENT", payload: record.instrument_id });
                        },
                    };
                }}
            />
        </div>
    );
}

export default COTMap;
import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import "./CommonStyles.css"
import {Select} from "antd";

const {Option} = Select;


function DirectionInput() {

    const dispatch = useDispatch()
    const direction = useSelector((state) => state.filters.direction);


    function directionChange(value) {
        dispatch({type: "SELECT_DIRECTION", payload: value});
    }

    return (
        <div className="MarginRight">
            <p className="filter-label"> Direction</p>
            <Select
                defaultValue={direction}
                style={{
                    width: 200,
                }}
                showSearch
                placeholder="Select a market"
                optionFilterProp="children"
                onChange={directionChange}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
                <Option value={"BS"} key={"BS"}>{"ALL"}</Option>
                <Option value={"B"} key={"B"}>{"B"}</Option>
                <Option value={"S"} key={"S"}>{"S"}</Option>
            </Select>
        </div>
    );
}

export default DirectionInput;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from "antd";
import "./CommonStyles.css"


function StopSizeInput() {

    const dispatch = useDispatch()
    const stopSize = useSelector((state) => state.filters.stop_size);

    // const [state_instrument, setInstrument] = useState("");

     function stopSizeChange(event) {
         if (event.key === 'Enter') {
            dispatch({type: "SELECT_STOP_SIZE", payload: event.target.value});
          }
         dispatch({type: "SELECT_STOP_SIZE", payload: event.target.value});
     }

     function handleKeyPress(event) {
        if (event.key === 'Enter') {
           dispatch({type: "SELECT_STOP_SIZE", payload: event.target.value});
         }
    }

    return (
        <div className="MarginRight">
            <p className="filter-label">Stop size, $</p>
            <Input type="text" defaultValue={stopSize} onBlur={stopSizeChange} onKeyDown={handleKeyPress}  />
        </div>
    );
}

export default StopSizeInput;

import * as React from "react";
import { useSelector } from 'react-redux'
import axios from "axios";
import "./SaveButton.css";
import { Button} from 'antd';
import LoginNotificationModal from "./LoginNotificationModal";
import { useState } from "react";


function SaveButton() {
    const cot_map = useSelector((state) => state.cot_map);
    const api_url = useSelector((state) => state.api_url);
    const text = "To save your personal watchlist, please log in. If you don't have an account, please register.";
    const [showModal, setShowModal] = useState(false);


    function saveClick() {
        const auth = localStorage.getItem("isLoggedIn");

        if (auth !== null) {
            let url = api_url + 'watchlist/update/';
            axios.post(url, cot_map,
                {
                    headers: {
                        'content-type': 'application/json',
                        "Authorization": "Token " + localStorage.getItem("token"),
                    },
                })
                .then(res => {
                    // console.log('posting complete', res.data);
                })
                .catch(err => {
                    console.log(err);
                })
        }
        else {
            setShowModal(true);
        }
    }

    return (
        <div>
            <Button onClick={saveClick}
                className="save-button"
                style={{ float: 'right' }}
                > SAVE WATCHLIST </Button>
                <LoginNotificationModal open={showModal} text={text} closeModal={() => setShowModal(false)} />
        </div>
    );
}

export default SaveButton;
import React from 'react';
import TabsPanel from './TabsPanel/TabsPanel';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from "axios";
import './MainWindow.css'
import Login from './Login/Login';
import Logout from './Logout/Logout';
import Register from './Register/Register';


function MainTab() {
    const dispatch = useDispatch();
    const api_url = useSelector((state) => state.api_url);
    const watchlist_path = useSelector((state) => state.watchlist_path);
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn"));

    let username = localStorage.getItem('username');

    function loadWatchlist() {
        let authorization = '';
        let isLoggedIn = localStorage.getItem("isLoggedIn");
        if (isLoggedIn !== null) {
            authorization = "Token " + localStorage.getItem("token")
        }

        axios.get(api_url + watchlist_path, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": authorization,
            },
            params: {}
        })
            .then(res => {
                dispatch({ type: "ADD_COT_MAP", payload: res.data });
            })
            .catch(err => {
                console.log(err);
            })
    }

    function loadInstruments() {
        let url = api_url + 'instruments/';
        axios.get(url,
            {headers: {"Content-Type": "application/json"}},
        )
            .then(res => {
                dispatch({ type: "SET_INSTRUMENTS", payload: res.data });
            }
            )
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(
        () => {
            loadWatchlist();
            loadInstruments();
            window.addEventListener('storage',
                (event) => {
                    loadWatchlist();
                    setIsLoggedIn(localStorage.getItem("isLoggedIn"));
                });
            //eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )

    return (
        <div className='main-window'>
            <div className='Margin'>
                <div></div>
                <div className="login-block">
                    <p className="user-name">{isLoggedIn && username}</p>
                    <Login isLoggedIn={isLoggedIn} />
                    <Logout isLoggedIn={isLoggedIn} />
                    <Register isLoggedIn={isLoggedIn} />
                </div>
            </div>
            <TabsPanel className='tab-panel' />
        </div>
    );
}

export default MainTab;



import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import "./CommonStyles.css"
import {Checkbox} from "antd";

function DMAFilter() {

    const dispatch = useDispatch()
    const dma = useSelector((state) => state.filters.dma);


    function DMAChange(e) {
        dispatch({type: "SELECT_DMA_FILTER", payload: e.target.checked});
    }

    return (
        <div className="MarginRight">
            <p className="filter-label">DMA filter</p>
            <Checkbox onChange={DMAChange} checked={dma}>Enable</Checkbox>
        </div>
    );
}

export default DMAFilter;

import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import "./WeeklyTabStyle.css";
import axios from "axios";
import WeeklyChart from "./WeeklyChart";
import InstrumentInput from "../../InstrumentInput/InstrumentInput"
import { Input, Button } from "antd";


export default function WeeklyTab(props) {
    const instrument = useSelector((state) => state.parameters.instrument);
    const [weeklyQuotes, setWeeklyQuotes] = useState([]);
    const [barsCount, setBarsCount] = useState(200);

    const [indicatorsWeeklyData, setIndicatorsWeeklyData] =
        useState({ 'wma': [], 'cot_index': [], 'seasonality_w': [], 'cot_start': [] });

    const api_url = useSelector((state) => state.api_url);
    const header = {
        headers: {
            "Content-Type": "application/json",
        },
    }

    function getWeeklyQuotes(barsCount) {
        // eslint-disable-next-line
        let url = api_url + "quotes/" + instrument + "/W/" + barsCount;
        axios.get(url, header)
            .then(res => {
                setWeeklyQuotes(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    function getWeeklyIndicators(barsCount) {
        let url = api_url + 'indicators/' + instrument + '/weekly/' + barsCount;

        axios.get(url, header)
            .then(res => {
                setIndicatorsWeeklyData(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    function onLoadButtonClick(){
        getWeeklyIndicators(barsCount);
        getWeeklyQuotes(barsCount);
    }

    function onBarsCountChange(e){
        setBarsCount(e.target.value);
    }

    useEffect(
        () => {
            getWeeklyIndicators(barsCount);
            getWeeklyQuotes(barsCount);
            //eslint-disable-next-line react-hooks/exhaustive-deps
        }, [instrument]
    )

    return (
        <div>
            <div className="instrument-bars-count-div"><InstrumentInput />
            <p className="bars-count-label">Bars Count:</p>
                <Input defaultValue="200" className="bars-count" onChange={onBarsCountChange}/>
                <Button type="link" onClick={onLoadButtonClick} className="load-button">Load</Button>
            </div>


            <WeeklyChart {...props}
                candleData={weeklyQuotes}
                indicatorsWeeklyData={indicatorsWeeklyData}
            ></WeeklyChart>
        </div>
    );
}
import PatternInput from "./PatternInput";
import RiskRewardInput from "./RiskRewardInput";
import MetricsList from "./MetricsList/MetricsList";
import StopSizeInput from "./StopSizeInput";
import DirectionInput from "./DirectionInput";
import DaysOfWeekFilter from "./DaysofWeekFilter";
import MonthFilter from "./MonthFilter";
import DMAFilter from "./DMAFilter";
import WMAFilter from "./WMAFilter";
import "./BacktestTab.css"
import CotFilter from "./CotFilter";
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import EquityChart from "./EquityChart/EquityChart";
import BacktestTable from "./BacktestTable";
import InstrumentInput from "../../InstrumentInput/InstrumentInput"


export default function BacktestTab(props) {
    const parameters = useSelector((state) => state.parameters);
    const filters = useSelector((state) => state.filters);
    const instrument = useSelector((state) => state.parameters.instrument);
    var param = { parameters: parameters, filters: filters }
    const dispatch = useDispatch()
    const api_url = useSelector((state) => state.api_url);
    const [equity, setEquity] = useState([]);
    const [metrics, setMetrics] = useState([]);


    function getMetrics(event) {
        let url = api_url + 'trades/metrics/';
        axios.post(url, param,
            {
                headers: {
                    'content-type': 'application/json',
                    // "Authorization": "Token " + localStorage.getItem("token"),
                },
            })
            .then(res => {
                setMetrics(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }

    function getEquity(event) {
        let url = api_url + 'trades/equity/';
        axios.post(url, param,
            {
                headers: {
                    'content-type': 'application/json',
                    // "Authorization": "Token " + localStorage.getItem("token"),
                },
            })
            .then(res => {
                setEquity(res.data);
            })
            .catch(err => {
                dispatch({ type: "ADD_EQUITY", payload: [] });
                console.log(err);
            })
    }



    function loadPatternsList() {
        let url = api_url + 'patterns/';
        axios.get(url,
            {
                headers: { "Content-Type": "application/json", }
            },
        )
            .then(res => {
                dispatch({ type: "SET_PATTERNS", payload: res.data });
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(
        () => {
            loadPatternsList();
            //eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )


    useEffect(
        () => {
            getEquity();
            getMetrics();
            //eslint-disable-next-line react-hooks/exhaustive-deps
        }, [instrument, parameters, filters]
    )

    return (

        <div className="chart_area">
            <div className="RowOfFilters">
                <div> <p className="filter-label">Instrument</p>
                    <InstrumentInput />
                </div>
                <PatternInput />
                <RiskRewardInput />
                <StopSizeInput />
                <DirectionInput />
                <WMAFilter />
                <DMAFilter />
                <CotFilter />

            </div>

            <div className="RowOfFilters">
                <DaysOfWeekFilter />
                <MonthFilter />
            </div>


            <div className="MetricAndChart" id="MetricAndChart">
                <MetricsList metrics={metrics} />
                <EquityChart {...props}
                    equityData={equity}
                    id="EquityChart"
                ></EquityChart>
            </div>

            <BacktestTable />
        </div>
    );
}

import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import "./DailyTabStyle.css";
import axios from "axios";
import DailyChart from "./DailyChart";
import InstrumentInput from "../../InstrumentInput/InstrumentInput"
import { Input, Button } from "antd";

export default function DailyTab(props) {
    const instrument = useSelector((state) => state.parameters.instrument);
    const [dailyQuotes, setDailyQuotes] = useState([]);
    const [indicatorsDaily, setIndicatorsDaily] = useState({ 'dma': [], 'percent_r': [], 'seasonality_d': [] });
    const api_url = useSelector((state) => state.api_url);
    const [barsCount, setBarsCount] = useState(200);
    const header = {
        headers: {
            "Content-Type": "application/json",
        },
    }


    function getDailyQuotes(barsCount) {
        // eslint-disable-next-line
        let url = api_url + 'quotes/' + instrument + '/D/' + barsCount;
        axios.get(url, header)
            .then(res => {
                setDailyQuotes(res.data);
            })
            .catch(err => {
                setDailyQuotes([]);
                console.log(err);
            })
    }


    function getDailyIndicators(barsCount) {
        let url = api_url + 'indicators/' + instrument + '/daily/' + barsCount;

        axios.get(url, header)
            .then(res => {
                setIndicatorsDaily(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(
        () => {
            getDailyQuotes(barsCount);
            getDailyIndicators(barsCount);
            //eslint-disable-next-line react-hooks/exhaustive-deps
        }, [instrument]
    )

    function onLoadButtonClick(){
        getDailyIndicators(barsCount);
        getDailyQuotes(barsCount);
    }

    function onBarsCountChange(e){
        setBarsCount(e.target.value);
    }

    return (
        <div>
            <div className="instrument-bars-count-div"><InstrumentInput />
                <p className="bars-count-label">Bars Count:</p>
                <Input defaultValue="200" className="bars-count" onChange={onBarsCountChange} />
                <Button type="link" onClick={onLoadButtonClick} className="load-button">Load</Button>
            </div>
            <DailyChart {...props}
                candleData={dailyQuotes}
                indicatorsDailyData={indicatorsDaily}
            ></DailyChart>
        </div>
    );
}

import React, { useEffect, useRef, useState } from "react";
import "./EquityChartStyle.css";
import { createChart, CrosshairMode, ColorType } from "lightweight-charts";

export default function EquityChart(props) {
    const {
        equityData,
        colors: {
            backgroundColor = 'white',
            lineColor = '#2962FF',
            textColor = 'black',
            areaTopColor = '#2962FF',
            areaBottomColor = 'rgba(41, 98, 255, 0.28)',
        } = {},
    } = props;

    const [chartHeight, setChartHeight] = useState(0);
    const [chartWidth, setChartWidth] = useState(0);


    const chartContainerRef = useRef();


    useEffect(
        () => {
            setChartHeight(document.getElementById("MetricAndChart").offsetHeight);
            setChartWidth(document.getElementById("MetricAndChart").offsetWidth * 0.75);
            // eslint-disable-next-line 
        }, []
    )


    useEffect(
        () => {
            const handleResize = () => {
                chart.applyOptions({
                    width: chartContainerRef.current.clientWidth,
                });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                },
                height: chartHeight,
                width: chartWidth,
                crosshair: {
                    mode: CrosshairMode.Magnet
                },
                leftPriceScale: {
                    visible: true,
                },
                autoSize: false,
            });
            chart.timeScale().fitContent();

            const equitySeries = chart.addLineSeries({
                color: 'rgba(92, 161, 238, 1)',
                lineWidth: 1,
                priceLineVisible: false,
            });

            equitySeries.setData(equityData);


            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
                chart.remove();
            };
        },
        // eslint-disable-next-line 
        [equityData, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor,]
    );

    return (
        <div
            ref={chartContainerRef}
            className='chart-container'
        />
    );
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import "./CommonStyles.css"
import {Select} from "antd";

const {Option} = Select;


function RiskRewardInput() {

    const dispatch = useDispatch()
    const riskReward = useSelector((state) => state.parameters.risk_reward);

     function riskRewardChange(value) {
         dispatch({type: "SELECT_RISK_REWARD", payload: value});
     }

    return (
        <div className="MarginRight">
            <p className="filter-label">Risk:Reward</p>
            <Select
                defaultValue={riskReward}
                style={{
                    width: 200,
                }}
                showSearch
                placeholder="Select a market"
                optionFilterProp="children"
                onChange={riskRewardChange}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
                <Option value={0} key={0}>{"No target"}</Option>
                <Option value={1} key={1}>{"1 (1:1)"}</Option>
                <Option value={2} key={2}>{"2 (1:2)"}</Option>
                <Option value={3} key={3}>{"3 (1:3)"}</Option>
            </Select>
        </div>
    );
}

export default RiskRewardInput;

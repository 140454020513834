import * as React from "react";
import { useSelector } from 'react-redux'
import axios from "axios";
import "./Register.css";
import { Button } from 'antd';
import { Modal } from 'antd';
import { useState } from "react";


function LoginModal({ open, closeModal }) {
    const api_url = useSelector((state) => state.api_url);
    const register_path = useSelector((state) => state.register_path);

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    function isEmailValid(val) {
        // eslint-disable-next-line
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
            return false;
        }
        else {
            return true;
        }
    }

    const handleRegister = () => {
        if (isEmailValid(username) !== true) {
            setErrorMessage("Invalid e-mail");
            return
        }

        if (password !== confirmPassword) {
            setErrorMessage("Passwords don't match");
            return
        }

        setErrorMessage("");

        axios.post(api_url + register_path, {
            "username": username,
            "password": password,
        },
            {
                headers: {
                    'content-type': 'application/json'
                },
            })
            .then(res => {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('isLoggedIn', "true");
                localStorage.setItem('username', username);
                window.dispatchEvent(new Event("storage"));
                closeModal();
            })
            .catch(err => setErrorMessage(err.response.data.error))

    };

    const handleCancel = () => {
        closeModal();
        setErrorMessage("");
    };

    return (
        <div>
            <Modal
                open={open}
                title="Register"
                onCancel={handleCancel}
                closable={false}
                width={400}
                footer={[
                    <div className="register-card-footer" key="footer">
                        <Button key="register" type="link" onClick={handleRegister} className="register-modal-button">
                            REGISTER
                        </Button>

                        <Button key="cancel" onClick={handleCancel} type="link" className="register-modal-button">
                            CANCEL
                        </Button>
                    </div>
                ]}
            >
                <div className="register-card">
                    <div className="register-card-column">
                        <label htmlFor="username" className="register-card-label">E-mail:</label>
                        <label htmlFor="password" className="register-card-label">Password: </label>
                        <label htmlFor="confirm_password" className="register-card-label">Confirm password: </label>
                    </div>
                    <div className="register-card-column ">
                        <input
                            type="text" id="username"
                            onChange={e => setUserName(e.target.value)}
                            className="register-card-input"
                            placeholder="E-mail"
                        />

                        <input
                            type="password" id="password"
                            onChange={e => setPassword(e.target.value)}
                            className="register-card-input"
                            placeholder="Password"
                        />

                        <input
                            type="password" id="confirm_password"
                            onChange={e => setConfirmPassword(e.target.value)}
                            className="register-card-input"
                            placeholder="Confirm password"
                        />
                    </div>
                </div>
                <div style={{ color: "red" }}>{errorMessage}</div>
            </Modal>
        </div>
    );
}

export default LoginModal;
import { useSelector, useDispatch } from 'react-redux'
import "./CommonStyles.css"
import { Select } from "antd";
import 'antd/dist/antd.min.css';

const { Option } = Select;

function PatternInput() {
    const dispatch = useDispatch()
    const pattern = useSelector((state) => state.parameters.pattern);
    const pattern_types = useSelector((state) => state.pattern_types);


    const patterns = pattern_types.map((pattern_types) =>
        <Option value={pattern_types.name} key={pattern_types.name}>{pattern_types.description}</Option>
    );


    function patternChange(value) {
        dispatch({ type: "SELECT_PATTERN", payload: value });
    }

    return (
        <div className="MarginRight">
            <p className="filter-label">Pattern</p>
            <Select
                defaultValue={pattern}
                style={{
                    width: 200,
                }}
                showSearch
                placeholder="Select a market"
                optionFilterProp="children"
                onChange={patternChange}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
                {patterns}
            </Select>
        </div>
    );
}

export default PatternInput;

import * as React from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import "./Login.css";
import { Button } from 'antd';
import { Modal } from 'antd';
import { useState } from "react";


function LoginModal({ open, closeModal }) {
    const api_url = useSelector((state) => state.api_url);
    const login_path = useSelector((state) => state.login_path);

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isErrorDisplay, setIsErrorDisplay] = useState('none');

    const handleLogin = () => {
        axios.post(api_url + login_path, {
            "username": username,
            "password": password
        },
            {
                headers: {
                    'content-type': 'application/json'
                },
            })
            .then(res => {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('isLoggedIn', "true");
                localStorage.setItem('username', username);
                window.dispatchEvent(new Event("storage"));
                setIsErrorDisplay('none');
                closeModal();
            })
            .catch(
                err => (
                    setIsErrorDisplay('') 
                )
            )
    };

    const handleCancel = () => {
        closeModal();
        setIsErrorDisplay('none'); 
    };

    return (
        <div>
            <Modal
                open={open}
                title="Log in to your account"
                onCancel={handleCancel}
                closable={false}
                width={350}
                footer={[
                    <div className="login-card-footer" key="footer">
                        <Button key="submit" type="link" onClick={handleLogin} className="login-modal-button">
                            LOGIN
                        </Button>
                        <Button key="back" onClick={handleCancel} type="link" className="login-modal-button">
                            CANCEL
                        </Button>
                    </div>
                ]}
            >
                <div className="login-card">
                    <div className="login-card-column">
                        <label htmlFor="username" className="login-card-label">E-mail:</label>
                        <label htmlFor="password" className="login-card-label">Password: </label>
                    </div>
                    <div className="login-card-column">
                        <input
                            type="email" id="username"
                            onChange={e => setUserName(e.target.value)}
                            className="login-card-input"
                            placeholder="E-mail"
                            required
                        />
                        <input
                            type="password" id="password"
                            onChange={e => setPassword(e.target.value)}
                            className="login-card-input"
                        />
                    </div>
                </div>
                <div style={{display: isErrorDisplay, color: "red"}}>Incorrect e-mail or password. Try again.</div>

            </Modal>
        </div>
    );
}

export default LoginModal;
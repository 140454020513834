import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import "./CommonStyles.css"
import {Select} from "antd";

const {Option} = Select;
// const children = [];


function MonthFilter() {

    const dispatch = useDispatch()
    const month = useSelector((state) => state.filters.month);


    function MonthChange(value) {
        dispatch({type: "SELECT_MONTH_FILTER", payload: value});
    }

    return (
        <div className="MarginRight">
            <p className="filter-label">Month filter</p>
            <Select
                mode="multiple"
                allowClear
                defaultValue={month}
                style={{
                    width: '100%',
                }}
                placeholder="Select a month"
                onChange={MonthChange}
            >
                <Option value={1} key={1}>{1}</Option>
                <Option value={2} key={2}>{2}</Option>
                <Option value={3} key={3}>{3}</Option>
                <Option value={4} key={4}>{4}</Option>
                <Option value={5} key={5}>{5}</Option>
                <Option value={6} key={6}>{6}</Option>
                <Option value={7} key={7}>{7}</Option>
                <Option value={8} key={8}>{8}</Option>
                <Option value={9} key={9}>{9}</Option>
                <Option value={10} key={10}>{10}</Option>
                <Option value={11} key={11}>{11}</Option>
                <Option value={12} key={12}>{12}</Option>
            </Select>
        </div>
    );
}

export default MonthFilter;

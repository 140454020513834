import * as React from "react";
import { useEffect, useState } from "react";
import { Table } from 'antd';
import "./BacktestTable";
import scrollIntoView from 'scroll-into-view';
import { useSelector} from 'react-redux'
import axios from "axios";


function BacktestTable(props) {
    const [trades, setTrades] = useState([]);
    const instrument = useSelector((state) => state.parameters.instrument); 
    const parameters = useSelector((state) => state.parameters);
    const filters = useSelector((state) => state.filters);
    var params = { parameters: parameters, filters: filters }

    const [pageSize, setPageSize] = useState(20);
    const [page, setPage] = useState(1);

    const api_url = useSelector((state) => state.api_url);

    const columns = [
        {
            title: 'Direction',
            dataIndex: 'direction',
            key: 'direction',
            align: 'center',
            width: '10%',
        },
        {
            title: 'Entry date',
            dataIndex: 'entry_date',
            key: 'entry_date',
            align: 'center',
            width: '30%',
        },
        {
            title: 'Exit date',
            dataIndex: 'exit_date',
            key: 'exit_date',
            align: 'center',
            width: '30%',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
            align: 'center',
            width: '30%',
        },
    ];

    const restoreScrollPosition = () => {
        scrollIntoView(document.querySelector('.scroll-row'), {
            align: {
                top: 0,
            },
        });
    }

    useEffect(
        () => {
            restoreScrollPosition();
        }, []
    )


    function getTrades(page, pageSize) {
        let offset = pageSize * (page - 1);
        let url = api_url + "trades/paginated/?limit=" + pageSize + "&offset=" + offset ;
        
        params.page_size = pageSize;
        axios.post(url, params,
            {
                headers: {
                    'content-type': 'application/json',
                    // "Authorization": "Token " + localStorage.getItem("token"),
                },
            })
            .then(res => {
                setTrades(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }


    useEffect(
        () => {
            getTrades(page, pageSize);
            //eslint-disable-next-line react-hooks/exhaustive-deps
        }, [instrument, parameters, filters]
    )

    return (
        <div>
            <Table
                tag="FavoritesTable"
                columns={columns}
                dataSource={trades.results}
                rowKey="id"
                rowIndex="currentRow"
                pagination={{
                    total: trades.count,
                    onChange: (page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize);
                        getTrades(page, pageSize);
                    },
                    pageSizeOptions: ["10", "20", "50"],
                    pageSize: pageSize
                }}
                size="small"
                scroll={{ x: 950, y: "calc(100vh - 200px)" }}
            />
        </div>
    );
}

export default BacktestTable;
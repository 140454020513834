import React, { useEffect, useRef } from "react";
import "./DailyTabStyle.css";
import { createChart, CrosshairMode, ColorType } from "lightweight-charts";

export default function DailyChart(props) {
    const {
        candleData,
        indicatorsDailyData,
        colors: {
            backgroundColor = 'white',
            lineColor = '#2962FF',
            textColor = 'black',
            areaTopColor = '#2962FF',
            areaBottomColor = 'rgba(41, 98, 255, 0.28)',
        } = {},
    } = props;

    const chartContainerRef = useRef();

    useEffect(
        () => {
            const handleResize = () => {
                // chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                },
                // width: chartContainerRef.current.clientWidth,
                // height: chartContainerRef.current.clientHeight,
                crosshair: {
                    mode: CrosshairMode.Magnet
                },
                leftPriceScale: {
                    visible: true,
                },
                autoSize: true,
            });
            // chart.timeScale().fitContent();


            const barSeries = chart.addBarSeries({
                upColor: "black",
                downColor: "black",
                thinBars: false,
                // borderDownColor: "#DE5E57",
                // borderUpColor: "#52A49A",
                // wickDownColor: "#838ca1",
                // wickUpColor: "#838ca1",
                priceFormat: {type: 'price', precision: 7, minMove: 0.0000001 }
            });

            barSeries.priceScale().applyOptions({
                autoScale: true, // disables auto scaling based on visible content
                scaleMargins: {
                    top: 0.05,
                    bottom: 0.2,
                },
            });
            barSeries.setData(candleData);

            const maSeries = chart.addLineSeries({
                color: "#DE5E57",
                lineWidth: 1,
                priceLineVisible: false,
            });

            maSeries.setData(indicatorsDailyData.dma);

            const percentRSeries = chart.addLineSeries({
                color: "#DE5E57",
                lineWidth: 1,
                priceScaleId: 'left',
                title:'percentR',
                priceLineVisible: false,
            });

            percentRSeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.8, // highest point of the series will be 70% away from the top
                    bottom: 0.1,
                },
            })

            percentRSeries.setData(indicatorsDailyData.percent_r);


            const seasonalitySeries = chart.addLineSeries({
                color: 'rgba(92, 161, 238, 1)',
                lineWidth: 1,
                priceScaleId: '',
                title: 'Seasonality',
                priceLineVisible: false,
            });

            seasonalitySeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.9, // highest point of the series will be 90% away from the top
                    bottom: 0,
                },
            })

            seasonalitySeries.setData(indicatorsDailyData.seasonality_d);


            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);

                chart.remove();
            };
        },
        [candleData, indicatorsDailyData, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
    );

    return (
        <div
            ref={chartContainerRef}
            className='daily-chart-container'
        />
    );
};

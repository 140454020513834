import React from "react";
import './Logout.css'
import { Button} from 'antd';
import { useNavigate } from "react-router-dom";

function Logout({ isLoggedIn } ) {
    let navigate = useNavigate();

    function onLogout() {
        localStorage.clear()
        window.dispatchEvent(new Event("storage"));
        navigate('/');
    }
    return (
        <div>
            {isLoggedIn && <Button onClick={onLogout} className="logout-button" type="link"> LOGOUT </Button>}
        </div>
    )
}

export default Logout;
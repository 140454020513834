import * as React from "react";
import "./LoginNotificationModal.css";
import { Button} from 'antd';
import { Modal } from 'antd';


function LoginNotificationModal({ open, text, closeModal }) {

    const handleOk = () => {
        closeModal();
    };

    return (
        <div>
            <Modal
                open={open}
                title="Login or register"
                onOk={handleOk}
                closable={false}  
                footer={[
                    <Button key="submit" type="link" onClick={handleOk} className="login-notification-button">
                        Ok
                    </Button>,
                ]}
            >
                <p>{text}</p>
            </Modal>
        </div>
    );
}

export default LoginNotificationModal;
import React, { useEffect, useRef } from "react";
import "./WeeklyTabStyle.css";
import { createChart, CrosshairMode, ColorType } from "lightweight-charts";

export default function ChartComponent(props) {

    const {
        candleData,
        indicatorsWeeklyData,
        colors: {
            backgroundColor = 'white',
            lineColor = '#2962FF',
            textColor = 'black',
            areaTopColor = '#2962FF',
            areaBottomColor = 'rgba(41, 98, 255, 0.28)',
        } = {},
    } = props;

    const chartContainerRef = useRef();

    useEffect(
        () => {
            const handleResize = () => {
                chart.applyOptions({
                    // width: chartContainerRef.current.clientWidth,
                    // height: chartContainerRef.current.clientHeight
                });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                },
                // width: chartContainerRef.current.clientWidth,
                // height: chartContainerRef.current.clientHeight,
                crosshair: {
                    mode: CrosshairMode.Magnet
                },
                leftPriceScale: {
                    visible: true,
                },
                autoSize: true,
            });
            // chart.timeScale().fitContent();


            const candleSeries = chart.addCandlestickSeries({
                upColor: "#52A49A",
                downColor: "#DE5E57",
                borderDownColor: "#DE5E57",
                borderUpColor: "#52A49A",
                priceFormat: { type: 'price', precision: 7, minMove: 0.0000001 }
            });


            function calcMarkers(markersData) {
                var markers = (markersData).map(({ time, value }) => {
                    if (value === 'S') {
                        return {
                            'time': time,
                            'position': 'aboveBar',
                            'color': 'red',
                            'shape': 'arrowDown'
                        }
                    }

                    if (value === 'B') {
                        return {
                            'time': time,
                            'position': 'belowBar',
                            'color': 'green',
                            'shape': 'arrowUp'
                        }
                    }

                    if (value === 'BC') {
                        return {
                            'time': time,
                            'position': 'belowBar',
                            'color': 'green',
                            'shape': 'circle'
                        }
                    }

                    if (value === 'SC') {
                        return {
                            'time': time,
                            'position': 'aboveBar',
                            'color': 'red',
                            'shape': 'circle'
                        }
                    }

                    else { return undefined }

                })

                return markers

            }

            let markers = calcMarkers(indicatorsWeeklyData.cot_start);
            markers = markers.filter(Boolean);
            candleSeries.setMarkers(markers);

            candleSeries.priceScale().applyOptions({
                autoScale: true, // disables auto scaling based on visible content
                scaleMargins: {
                    top: 0.05,
                    bottom: 0.2,
                },
            });
            candleSeries.setData(candleData);

            const maSeries = chart.addLineSeries({
                color: "#DE5E57",
                lineWidth: 1,
                priceLineVisible: false,
            });

            maSeries.setData(indicatorsWeeklyData.wma);

            const cotIndexSeries = chart.addLineSeries({
                color: "#DE5E57",
                lineWidth: 1,
                priceScaleId: 'left',
                title: 'COT Index 26',
                priceLineVisible: false,
            });

            cotIndexSeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.8, // highest point of the series will be 70% away from the top
                    bottom: 0.1,
                },
            })

            cotIndexSeries.setData(indicatorsWeeklyData.cot_index);


            const seasonalitySeries = chart.addLineSeries({
                color: 'rgba(92, 161, 238, 1)',
                lineWidth: 1,
                priceScaleId: '',
                title: 'Seasonality',
                priceLineVisible: false,
            });

            seasonalitySeries.priceScale().applyOptions({
                scaleMargins: {
                    top: 0.9, // highest point of the series will be 90% away from the top
                    bottom: 0,
                },
            })

            seasonalitySeries.setData(indicatorsWeeklyData.seasonality_w);


            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);

                chart.remove();
            };
        },
        [candleData, indicatorsWeeklyData, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
    );

    return (
        <div
            ref={chartContainerRef}
            className='weekly-chart-container'
        />
    );
};
